<template>
  <div class="d-flex justify-center bg-light-grey">
    <div class="wrapper d-flex flex-column align-center mt-6">
      <h1 class="text-center mb-10">Munkatársak</h1>
      <v-row class="d-flex justify-center px-6 pb-10">
        <v-col xs="12" sm="6" md="6" lg="6" v-for="index in stuff_items.length" :key="index">
          <app-stuff-card :item="stuff_items[index-1]"></app-stuff-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import StuffCard from "@/components/Stuff/StuffCard";

export default {
  name: "Stuff",
  data() {
    return {
      stuff_items: [
        {
          name: "Dr. Ozsváth János",
          src: require('../../assets/images/dr_ozsvath_janos.png'),
          description: "Diplomámat a Debreceni Egyetem Fogorvostudományi szakán szereztem. 2013 júniusától veszek részt a Mosolysziget Dentál Kft. munkájában. Célom, hogy oldott légkörben találjunk megoldást pácienseink problémájára, legyen az egészen egyszerű vagy akár összetett.\n" +
              "\n" +
              "2014 őszétől mint a konzerváló fogászat és fogpótlástan szakorvosa állok kedves vendégeink rendelkezésére.\n" +
              "\n" +
              "2017 tavaszától Hajdúhadház I. számú fogorvosi körzetének teendőit is ellátom."
        },
        {
          name: "Ozsváth Jánosné",
          src: require('../../assets/images/ozsvath_janosne.png'),
          description: "2012-ben szereztem meg fogászati szakasszisztensi képesítésemet, 2013 -tól pedig mint dentálhigénikus is részt veszek rendelőnk munkájában. Célom, hogy lendületes és empatikus hozzáállással gördülékenyebbé tegyem a rendelést a bejelentkezéstől egészen a kezelés végéig."
        }
      ]
    }
  },
  components: {
    appStuffCard: StuffCard
  },
}
</script>

<style scoped>

</style>
