<template>
  <div class="informing d-flex justify-center align-self-center py-2">
    <v-row class="align-center">
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <informingSection icon="mdi-clock-outline" title="Nyitvatartás"
                          subtitle="Kedd: 16-19" subsubtitle="Csütörtök: 16-19"></informingSection>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <informingSection icon="mdi-phone" title="Telefon" subtitle="+3670/3717-600"></informingSection>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <informingSection icon="mdi-map-marker-outline" title="Címünk"
                          subtitle="4030 Debrecen, Oláh Károly u. 54."
                          subsubtitle="4030 Debrecen, Tinódi u. 8. (fehér kerítés)"></informingSection>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InformingSection from "@/components/Informing/InformingSection";

export default {
  name: "Informing",
  components: {
    informingSection: InformingSection
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/styles';

.informing {
  width: 75%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
    padding: 5px;
  }
}
</style>
