<template>
  <v-card min-width="250px" style="height: 100%">
    <v-card-title class="d-flex flex-column align-center">
      <v-avatar :size="120" class="avatar">
        <div class="image-wrapper">
          <img :src="item.src" alt="{{item.name}}">
        </div>
      </v-avatar>
      <div>{{ item.name }}</div>
    </v-card-title>
    <v-card-text>
      <p style="text-align: center;">{{ item.description }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "StuffCard",
  props: ['item'],
}
</script>

<style scoped lang="scss">
.image-wrapper {
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
