<template>
  <v-app>
    <informing></informing>
    <app-header></app-header>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
import Informing from "@/components/Informing/Informing";

export default {
  name: 'App',

  components: {
    appHeader: Header,
    informing: Informing
  },

  data: () => ({
    //
  }),
}
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
