import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import { createRouter, createWebHistory } from "vue-router";
import {routes} from "@/routs";

loadFonts()

const router = createRouter({
    history: createWebHistory(),
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition
    //     }
    //     if (to.hash) {
    //         return {selector: to.hash};
    //     } else {
    //         return {x: 0, y: 0}
    //     }
    // }
});

const app = createApp(App);
app.use(vuetify);
app.use(router);

router.isReady().then(() => {
    app.mount('#app');
})
