<template>
  <div class="d-flex justify-center bg-light-grey">
    <div class="wrapper d-flex flex-column align-center mt-6">
      <v-card class="introduction_card px-6 py-5">
        <v-card-text>
          <p class="title text-center mb-3">Szeretné megbízható kezekben tudni fogait?</p>
          <p class="text-center">
            Jöjjön el hozzánk, ahol barátságos környezetben, empatikus hozzáállással várjuk kedves pacienseinket.
          </p>

          <p class="py-2 text-center">
            Célunk betegeink maximális elégedettségének elérése, melyet magas szintű szakmai munkával és kiváló minőségű
            anyagok, korszerű technikák felhasználásával kívánunk biztosítani.
          </p>
          <p class="text-center">
            Látogasson el hozzánk, hiszen számunkra az Ön mosolya a legfontosabb.
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduction"
}
</script>

<style scoped lang="scss">
@import '~vuetify/styles';

.title{
  width: 100%;
  font-size: large;
  font-weight: bold;
}

.introduction_card {
  min-width: 300px;
  max-width: 50%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 100%;
  }
}
</style>
