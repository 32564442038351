<template>
  <div class="d-flex justify-center">
    <v-alert
        shaped
        dense
        dark
        type="warning"
        v-if="new Date() <= new Date('2024-06-01')"
    >
      Kedves vendégeink! <strong>2024.május 27</strong>-e és <strong>2024.május 31</strong>-e között zárva tartunk!
    </v-alert>
  </div>
  <div id="toolbar_wrapper" class="d-flex justify-center">
    <div id="toolbar" class="d-flex justify-center">

      <router-link :to="{name: 'Home'}" v-slot="{ href, route, navigate, isActive, isExactActive }">
        <v-btn variant="text" id="icon_button">
          <v-img
              alt="Mosolysziget Dental Logo"
              class="shrink mr-2"
              contain
              src="../../assets/images/Logo.png"
              width="150"
          />
        </v-btn>
      </router-link>

      <v-spacer></v-spacer>

      <v-btn id="toogleButton" variant="text" class="hidden-md-and-up" @click="toggleMenu = !toggleMenu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div class="custom-menu"
           :class="[isMobile ? 'custom-menu__desktop': 'custom-menu__mobile', toggleMenu?'custom-menu__mobile--active':'']">
        <router-link :to="{name: 'Home'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn variant="text" class="menu-item menu-item--home" :active="isActive">
            <div class="menu-item__text">Főoldal</div>
          </v-btn>
        </router-link>

        <router-link :to="{name: 'Contact'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn variant="text" class="menu-item menu-item--contact" :active="isActive">
            <div class="menu-item__text">Kapcsolatok</div>
          </v-btn>
        </router-link>

        <router-link :to="{name: 'Price'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn variant="text" class="menu-item menu-item--contact" :active="isActive">
            <div class="menu-item__text">Áraink</div>
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleMenu: false,
    }
  },
  components: {},
  methods: {
    setToogleMenu() {
      if (this.$vuetify.display.smAndDown) {
        this.toggleMenu = !this.toggleMenu;
      }
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mdAndUp
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/main";

::v-deep .v-btn {
  padding: 3px 5px;
}

#toolbar_wrapper {
  background: $color-light-grey;
  padding: 10px 0;
  position: relative;
}

#toolbar {
  width: 75%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
    padding: 5px;
  }

  & a {
    text-decoration: none;
    color: $color-black !important;
  }
}

#icon_button {
  width: fit-content;
}

#toogleButton {
  margin-left: 10px;
  width: fit-content;
  padding: 5px;
}

.custom-menu {
  display: flex;
  justify-content: end;
  z-index: 1000;

  &__desktop {
    flex-direction: row;
  }

  &__mobile {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    flex-direction: column;
    display: none;
    background-color: white;

    &--active {
      display: flex;
    }

    & .menu-item {
      width: 100%;
    }
  }

  & a {
    text-decoration: none;
    color: $color-black !important;
  }
}

.menu-item {
  margin-right: 10px;
  color: $color-my-primary !important;
  border-color: $color-my-primary !important;

  &__icon {
    border: 1px solid;
    padding: 5px;
    margin-bottom: 7px;
    border-radius: 100%;
  }
}


</style>
