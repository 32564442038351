<template>
  <div class="d-flex flex-column align-center">
    <v-card class="contact_card">
      <v-card-text class="d-flex pa-4">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="9" lg="9">
            <GoogleMap api-key="AIzaSyCXTgDcSHPqPGf2cv-rYr8wv73GNe8Zcg8" style="width: 100%; height: 500px"
                       :center="center" :zoom="17">
              <Marker :options="{ position: center }"/>
              <Marker :options="{ position: tinodi }"/>
            </GoogleMap>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <div class="d-flex flex-column align-baseline">
              <informingSection class="mt-4" icon="mdi-clock-outline" title="Nyitvatartás"
                                subtitle="Kedd: 16-19" subsubtitle="Csütörtök: 16-19"></informingSection>

              <informingSection class="mt-4" icon="mdi-information-outline" title="Információ"
                                subtitle="Előzetes egyeztetést követően a megjelölt időpontokon kívül is várjuk kedves vendégeinket."></informingSection>

              <informingSection class="mt-4" icon="mdi-phone" title="Telefon"
                                subtitle="+3670/3717-600"></informingSection>

              <informingSection class="mt-4" icon="mdi-map-marker-outline" title="Címünk"
                                subtitle="4030 Debrecen, Oláh Károly u. 54."
                                subsubtitle="4030 Debrecen, Tinódi u. 8. (fehér kerítés)"></informingSection>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {GoogleMap, Marker} from "vue3-google-map";
import InformingSection from "@/components/Informing/InformingSection";

export default {
  name: "Contact",
  components: {GoogleMap, Marker, informingSection: InformingSection},
  data() {
    return {
      googleMaps_API: "AIzaSyCXTgDcSHPqPGf2cv-rYr8wv73GNe8Zcg8",
      center: {lat: 47.514632, lng: 21.637042},
      tinodi: {lat: 47.514977, lng: 21.637437},
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/main";

.contact_card {
  width: 75%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 90%;
  }
}
</style>
