<template>
  <div class="informing-section d-flex align-center">
    <v-icon class="informing__icon mx-1">{{ icon }}</v-icon>
    <div>
      <p class="informing-section__title ">{{ title }}</p>
      <p class="informing-section__subtitle">{{ subtitle }}</p>
      <p class="informing-section__subtitle">{{ subsubtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformingSection",

  props: ['icon', 'title', 'subtitle', 'subsubtitle']
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/abstracts/_variable.scss";

.informing-section{
  color: $color-my-primary !important;

  &__subtitle {
    color: $color-grey;
  }
}
</style>
