<template>
  <v-card min-width="250px" style="height: 100%">
    <v-card-title class="d-flex flex-column align-center">
      <v-avatar :size="120" class="avatar">
        <div class="image-wrapper">
          <img :src="getImgUrl(item.image)" alt="Icon - Mosolysziget">
        </div>
      </v-avatar>
      <div>{{ item.title }}</div>
    </v-card-title>
    <v-card-text>
      <p style="text-align: center;">{{ item.text }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "IconCard",
  props: ['item'],
  data(){
    return {
    }
  },
  methods: {
    getImgUrl(pic) {
      return require('../assets/icons/'+pic)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/abstracts/_variable.scss";

.image-wrapper {
  width: 85px;
  height: 85px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar{
  background-color: $color-my-primary
}
</style>
