<template>
  <div class="d-flex justify-center bg-light-grey pb-5">
    <div class="wrapper d-flex flex-column align-center mt-6">
      <h1 class="text-center mb-10">Szolgáltatások</h1>
      <v-row class="d-flex justify-center">
        <v-col xs="12" sm="6" md="4" lg="4" v-for="index in services_list.length" :key="index">
          <app-icon-card :item="services_list[index-1]"></app-icon-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import IconCard from "@/components/IconCard";

export default {
  name: "Services",
  components: {
    appIconCard: IconCard
  },
  data() {
    return {
      services_list: [
        {
          image: "tooth_care.png",
          title: "Fogápolás",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in suscipit urna, non eleifend lorem. Integer egestas maximus lorem, sit amet dictum est sagittis sit."
        },
        {
          image: "tooth_care.png",
          title: "Fogápolás",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in suscipit urna, non eleifend lorem. Integer egestas maximus lorem, sit amet dictum est sagittis sit."
        },
        {
          image: "tooth_care.png",
          title: "Fogápolás",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in suscipit urna, non eleifend lorem. Integer egestas maximus lorem, sit amet dictum est sagittis sit."
        },
        {
          image: "tooth_care.png",
          title: "Fogápolás",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in suscipit urna, non eleifend lorem. Integer egestas maximus lorem, sit amet dictum est sagittis sit."
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
