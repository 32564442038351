<template>
  <v-carousel hide-delimiters height="400px" :show-arrows="false">
    <v-carousel-item
        v-for="(item, index) in items"
        :key="index"
    >
      <v-row class="flex-nowrap" style="height:100%; margin: 0;">
        <template v-for="(n,i) in columns">
          <template v-if="i < item.src.length">
            <v-col :key="i" style="padding: 0;">
              <v-sheet v-if="i < item.src.length"
                       height="100%"
              >
                <v-row class="fill-height d-flex flex-column justify-space-around"
                       align="center"
                       justify="center"
                       style="margin: 0"
                >
                  <div class="img-wrapper">
                    <img :src="item.src[i]" alt="Slideshow - Mosolysziget"/>
                  </div>
                </v-row>
              </v-sheet>
            </v-col>
          </template>
        </template>
        <div class="carousel-text-box">
          <v-card class="rounded-lg" color="rgb(255, 255, 255, 0.8)">
            <v-card-text class="pa-3">{{ carouselText }}</v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-carousel-item>
  </v-carousel>

  <app-introduction></app-introduction>
  <!--  <app-services></app-services>-->
  <app-stuff></app-stuff>
</template>

<script>
import Services from "@/components/Services";
import Stuff from "@/components/Stuff/Stuff";
import Introduction from "@/components/Introduction";

export default {
  title() {
    return `Mosolysziget Dental - Home`
  },
  name: "Home",
  data() {
    return {
      items: [
        {
          src: [require('../assets/images/carousel/img.png'), require('../assets/images/carousel/img_1.png')],
        },
        // {
        //   src: [require('../assets/images/carousel/img_2.png'), require('../assets/images/carousel/img_3.png')],
        // },
      ],
      carouselText: "Rendelőnk 2013-ban nyitotta meg kapuit Debrecenben. Azóta is nagy figyelmet fordítunk arra, hogy pácienseinket partnernek tekintve közösen találjunk megoldást fogászati panaszaikra. Mindeközben törekszünk fájdalommentes kezelésekre, hogy a fogászati beavatkozások minél inkább stresszmentesek lehessenek."
    }
  },
  components: {
    appServices: Services,
    appStuff: Stuff,
    appIntroduction: Introduction,
  },
  computed: {
    columns() {
      switch (this.$vuetify.display.name) {
        case "xxl":
        case "xl":
        case "lg":
        case "md":
          return 2;
        case "sm":
          return 1;
        default:
          return 1;
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/main";

.carousel-text-box {
  max-width: 400px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;

  @media #{map-get($display-breakpoints, 'xs')} {
    max-width: 100%;
    width: 100%;
  }
}

.img-wrapper {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
